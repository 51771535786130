import { createTheme } from "@material-ui/core/styles";
import { TreeViewClassKey } from "@material-ui/lab/TreeView";
import { TreeItemClassKey } from "@material-ui/lab/TreeItem";
import * as _styles from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles/colorManipulator";

declare module "@material-ui/core/styles/overrides" {
  export interface ComponentNameToClassKey {
    MuiTreeView: TreeViewClassKey;
    MuiTreeItem: TreeItemClassKey;
  }
}

// A custom theme for this app
const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#5F3FA5",
      dark: "#231231",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#5F3FA5",
    },
    background: {
      default: "#efefef",
    },
    action: {
      selected: "#5F3FA5",
      selectedOpacity: 0.5,
    },
  },
});

theme.overrides = {
  MuiButton: {
    root: {
      borderRadius: 4, // square corners
      textTransform: "none", // removes uppercase transformation
      fontWeight: 600,
      "&:hover": {
        backgroundColor: theme.palette.secondary.light,
        color: "white",
      },
    },
    outlinedPrimary: {
      borderWidth: "2px",
      "&:hover": {
        borderWidth: "2px",
        backgroundColor: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
      },
    },
    containedPrimary: {
      borderWidth: "2px",
      "&:hover": {
        borderWidth: "2px",
        backgroundColor: theme.palette.secondary.light,
        borderColor: theme.palette.secondary.light,
      },
    },
  },
  MuiAppBar: {
    root: {
      padding: "0px",
    },
    colorPrimary: { backgroundColor: theme.palette.primary.dark },
    colorDefault: {
      backgroundColor: theme.palette.common.white,
      color: theme.palette.common.black,
    },
  },
  MuiSwitch: {
    root: {
      width: 37,
      height: 20,
      padding: 0,
      margin: theme.spacing(1)
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(18px)',
        '& + $track': {
          opacity: 0.5,
          border: 'none'
        }
      },
      '&$focusVisible $thumb': {
        border: '6px solid #fff'
      }
    },
    thumb: {
      width: 15,
      height: 15
    },
    track: {
      borderRadius: 20 / 2,
      backgroundColor: theme.palette.grey[300],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border'])
    }
  },
  MuiTreeItem: {
    root: {
      "&:focus > $content $label": {
        backgroundColor: theme.palette.action.hover,
      },
      "&$selected > $content $label": {
        backgroundColor: _styles.alpha(
          theme.palette.secondary.main,
          theme.palette.action.selectedOpacity
        ),
      },
      "&$selected > $content $label:hover, &$selected:focus > $content $label":
        {
          backgroundColor: _styles.alpha(
            theme.palette.secondary.main,
            theme.palette.action.selectedOpacity +
              theme.palette.action.hoverOpacity
          ),
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            backgroundColor: "transparent",
          },
        },
    },
  },
  MuiFab: {
    primary: {
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.primary.contrastText,
    },
  },
  MuiInputBase: {
    root: {
      fontSize: "inherit",
    },
  },
  MuiOutlinedInput: {
    input: {
      padding: "9px 9px",
    },
    root: {
      "&$focused .MuiOutlinedInput-notchedOutline": {
        borderColor: alpha("#000000", 0.87),
      },
    },
  },
  MuiListItem: {
    root: {
      "&$focusVisible": {
        backgroundColor: theme.palette.action.selected,
      },
      "&$selected, &$selected:hover": {
        backgroundColor: _styles.alpha(
          theme.palette.action.selected,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: "20px",
    },
  },
  MuiListItemText: {
    root: {
      paddingTop: "3px",
      padding: "0px",
      margin: "0px",
      marginTop: "0px",
      marginBottom: "0px",
    },
  },
  MuiCssBaseline: {
    "@global": {
      "*::-webkit-scrollbar": {
        width: 19,
        height: 19,
      },
      "*::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, 0)",
      },
      "*::-webkit-scrollbar-thumb": {
        backgroundColor: "#8f93ad",
        borderRadius: "20px",
        border: "solid 6px white",
      },
    },
  },
  MuiCard: {
    root: {
      // borderRadius: '4px',
      // border: '1px solid black',
      boxShadow: "none",
      marginTop: "-1px",
      marginLeft: "-1px",
    },
  },
  MuiCardContent: {
    root: {
      borderBottomLeftRadius: "4px",
      borderBottomRightRadius: "4px",
      border: "1px solid black",
      padding: "8px",
      "&:last-child": {
        "padding-bottom": 0,
      },
    },
  },
  MuiFormLabel: {
    root: {
      "&$focused": {
        color: alpha("#000000", 0.87),
      },
    },
  },
  MuiFormControlLabel: {
    root: {
      height: "34px",
      marginRight: "8px",
    },
  },
  MuiCollapse: {
    wrapperInner: {
      width: "unset",
    },
  },
  MuiBreadcrumbs: {
    root: { color: "white !important" },
    li: {
      "& button": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
  },
  MuiDialog: {
    paper: {
      minWidth: "300px",
      "& .MuiDialogTitle-root": {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.primary.contrastText,
        margin: 0,
        paddingLeft: "23px",
        paddingRight: "23px",
        paddingTop: "4px",
        paddingBottom: "4px",
        "& .MuiTypography-root": {
          "& .MuiTypography-body1": {
            fontSize: "16px",
            fontWeight: 700,
          },
        },
      },
      "& .MuiDialogContent-root": {
        padding: "23px",
        paddingBottom: "20px",
        "& .MuiTypography-root": {
          color: "black",
          fontSize: "14px",
          margin: "0",
        },
      },
      "& .MuiDialogActions-root": {
        paddingTop: "0px",
      },
    },
  },
  MuiAccordionSummary: {
    root: {
      minHeight: "20px",
      "&.Mui-expanded": {
        minHeight: "20px",
      },
      "&:hover": {
        backgroundColor: "#f2eff9",
      },
    },
    content: {
      padding: 0,
      margin: 0,
      "&.Mui-expanded": {
        padding: 0,
        margin: 0,
      },
    },
    expandIcon: {
      padding: "0",
      margin: 0,
    },
  },
  MuiTooltip: {
    tooltipPlacementBottom: {
      marginBottom: "5px",
      marginTop: "5px",
      backgroundColor: "rgba(102, 51, 153, 0.87)",
      fontFamily: "Open Sans",
      fontSize: "12px",
      color: "#FFFFFF",
    },
    tooltipPlacementTop: {
      marginBottom: "5px",
      marginTop: "5px",
      backgroundColor: "rgba(102, 51, 153, 0.87)",
      fontFamily: "Open Sans",
      fontSize: "12px",
      color: "#FFFFFF",
    },
  },
};

export default theme;
