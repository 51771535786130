export type FormsEmptyListProps = {
  message: string;
};

export const FormsEmptyList = ({ message }: FormsEmptyListProps) => {
  return (
    <div
      className={
        "flex-grow flex-1 my-2 font-semibold text-center align text-gray-500 flex justify-center items-center p-4"
      }
    >
      <span>{message}</span>
    </div>
  );
};
