import { useContext } from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardTitle from "../CardTitle/CardTitle";
import { NodeContext } from "@dispatcher-stratus/stratus-react";
import ReorderView from "./ReorderView";
import { useTranslation } from "react-i18next";

export interface NodeFormPanelProps {
  className?: string;
}

export const NodeFormPanel = (props: NodeFormPanelProps): JSX.Element => {
  const { state } = useContext(NodeContext);
  const { t } = useTranslation();

  return (
    <Card
      className={`flex flex-col ${props.className} ${
        !state.node.isEnabled ? "bg-gray-200" : ""
      }`}
      style={{ minHeight: "50px", height: "50px" }}
    >
      <CardTitle title={t("Selected Forms")} />
      <CardContent className="flex flex-col flex-grow overflow-y-scroll py-1 pr-0">
        {!(state && state.hash) || !state.node.isEnabled ? (
          ""
        ) : (
          <ReorderView className="flex-grow" />
        )}
      </CardContent>
    </Card>
  );
};

export default NodeFormPanel;
