import { IndexForm } from "../components/NodeFormPanel/helper";

export interface NodeConfig {
  nodes: {
    indexForms: IndexForm[];
    checked: IndexForm[];
  };
}

export const INITIAL_NODE_CONFIG: NodeConfig = {
  nodes: {
    indexForms: [],
    checked: [],
  },
};
