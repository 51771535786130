import * as Designer from "@dispatcher-stratus/stratusjs";
import { isArray } from "lodash";

export interface IndexForm {
  guid: string;
  name: string;
  nameSpace: string;
  isCustomForm?: boolean;
}

export const getAllIndexFormNodes = async (
  nodeId: string,
  workflowId: string
): Promise<IndexForm[]> => {
  const params = {
    nodeid: nodeId,
    wfid: workflowId,
  };
  const results = await Designer.getAllIndexFormSupportedNodes(params);
  return (results?.data?.nodes as IndexForm[]) || [];
};

export const getNodes = async (
  nodeId: string,
  workflowId: string
): Promise<IndexForm[]> => {
  const params = {
    nodeid: nodeId,
    wfid: workflowId,
  };
  const results = await Designer.getIndexFormNodes(params);
  return (results?.data?.nodes as IndexForm[]) || [];
};

export const setNodes = async (
  nodeId: string,
  workflowId: string,
  nodes: IndexForm[]
): Promise<any> => {
  const params = {
    nodeid: nodeId,
    wfid: workflowId,
    data: {
      nodes: nodes,
    },
  };
  const results = await Designer.setIndexFormNodes(params);
  nodes.forEach(async (el) => {
    const params = {
      data: { guid: el.guid },
    };
    if (el.nameSpace === "WebDav") {
      const errors = await Designer.getNodeStatus(params as any).then(
        (status) => (status?.data?.status as any)?.errors["en-us"]
      );
      if (
        isArray(errors) &&
        errors.includes("A WebDAV server URL is required.")
      )
        return;
    }
    const payload = {
      data: {
        guid: el.guid,
        status: {
          isValid: true,
          errors: {
            "en-US": [],
          },
        },
      },
    };
    await Designer.updateNodeStatus(payload as any)
      .then(console.log)
      .catch(console.log);
  });
  return results;
};

export const getIndexFormReferences = async (
  nodeId: string,
  workflowId: string
): Promise<any> => {
  const params = {
    nodeid: nodeId,
    wfid: workflowId,
  };
  const results = await Designer.getIndexFormReferences(params);
  return results;
};

export const editIndexForm = async (
  nodeId: string,
  workflowId: string,
  guid: string
): Promise<any> => {
  const params = {
    nodeid: nodeId,
    wfid: workflowId,
    data: {
      guid: guid,
    },
  };
  const results = await Designer.editIndexFormNode(params);
  return;
};

export const reorder = (list: any, startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export const getItemStyle = (isDragging?: boolean, draggableStyle?: any) => ({
  // styles we need to apply on draggables
  ...draggableStyle,
  ...{
    marginTop: "4px",
    marginBottom: "4px",
    padding: "1px",
    border: "2px solid black",
    borderRadius: "4px",
    backgroundColor: "#f2eff9",
    borderColor: "#c5b9e1",
  },
});

export const getButtonStyle = (isDragging?: boolean, draggableStyle?: any) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    display: "none",
  }),

  ...{
    marginRight: "-12px",
  },
});

export const getListStyle = (isDraggingOver: boolean) => ({
  //background: isDraggingOver ? 'lightblue' : 'lightgrey',
});
