import React from "react";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardTitle from "../CardTitle/CardTitle";
import List from "@material-ui/core/List";
import * as Helper from "./helper";
import {
  NodeContext,
  NodeContextType,
  NodeStateActions,
} from "@dispatcher-stratus/stratus-react";
import { useTranslation } from "react-i18next";
import { FormItem } from "./FormItem";
import { FormsEmptyList } from "./FormsEmptyList";

export interface FormsPanelProps {
  className?: string;
  refresh?: object;
}

export const FormsPanel = (props: FormsPanelProps): JSX.Element => {
  const [hideTooltip, setHideTooltip] = React.useState(false);
  const [checked, setChecked] = React.useState<Helper.IndexForm[]>([]);
  const [indexForms, setIndexForms] = React.useState<Helper.IndexForm[]>([]);
  const { state, dispatch, stratusDesigner } =
    React.useContext<NodeContextType>(NodeContext);
  const { t } = useTranslation();

  React.useEffect(() => {
    Helper.getNodes(state.nodeid, state.wfid)
      .then((checked) => {
        dispatch({
          type: NodeStateActions.UPDATE_NODE_CONFIG_AND_HASH,
          payload: {
            nodes: {
              ...state.node.config.nodes,
              checked: checked,
            },
          },
        });
        setChecked(checked);
      })
      .finally(() => {});
  }, [state.nodeid, state.wfid]);

  React.useEffect(() => {
    Helper.getAllIndexFormNodes(state.nodeid, state.wfid)
      .then((indexForms) => {
        indexForms.sort((a, b) => {
          return a.name.localeCompare(b.name, undefined, {
            numeric: true,
            sensitivity: "base",
          });
        });
        return indexForms;
      })
      .then(setIndexForms)
      .finally(() => {});
  }, [state.nodeid, state.wfid, props.refresh]);

  React.useEffect(() => {
    dispatch({
      type: NodeStateActions.UPDATE_NODE_CONFIG,
      payload: {
        nodes: {
          ...state.node.config.nodes,
          checked: checked,
        },
      },
    });
  }, [checked]);

  React.useEffect(() => {
    dispatch({
      type: NodeStateActions.UPDATE_NODE_CONFIG_AND_HASH,
      payload: {
        nodes: {
          ...state.node.config.nodes,
          indexForms: indexForms,
        },
      },
    });
    dispatch({
      type: NodeStateActions.UPDATE_NODE_CONFIG_AND_HASH,
      payload: {
        nodes: {
          ...state.node.config.nodes,
          checked: state.node.config.nodes.checked.map(
            (el: Helper.IndexForm) => {
              let val: Helper.IndexForm = el;
              indexForms.forEach((indexForm) => {
                if (indexForm.guid === el.guid) {
                  val = indexForm;
                  return;
                }
              });
              return val;
            }
          ),
        },
      },
    });
  }, [indexForms]);

  const handleToggle = (value: string) => {
    const currentIndex = state.node.config.nodes.checked.findIndex(
      (el: Helper.IndexForm) => el.guid === value
    );
    const newChecked = [...state.node.config.nodes.checked];
    const indexForm = indexForms.find(
      (el: Helper.IndexForm) => el.guid === value
    );

    if (indexForm === undefined) return;
    if (currentIndex === -1 && indexForm !== undefined) {
      newChecked.push(indexForm);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleNodeEditClick = (value: string) => {
    Helper.editIndexForm(state.nodeid, state.wfid, value);
  };

  return (
    <>
      <Card
        className={`flex flex-col w-full flex-grow ${
          !state.node.isEnabled ? "bg-gray-200" : ""
        }`}
        style={{
          minHeight: "50px",
          height: "50px",
          flexBasis: "50px",
          flexGrow: "1",
        }}
      >
        <CardTitle title={t("Custom Forms")} />
        <CardContent className="flex flex-col flex-grow overflow-y-scroll p-0">
          {state &&
            state.hash &&
            state.node.isEnabled &&
            indexForms.filter((f: Helper.IndexForm) => f.isCustomForm).length >
              0 && (
              <List className={`p-0 px-3 pr-0 flex-grow flex-1 my-2}`}>
                {indexForms
                  .filter((f: Helper.IndexForm) => f.isCustomForm)
                  .map((item, index) => {
                    const labelId = `checkbox-list-label-${item.guid}`;
                    return (
                      <div key={item.guid}>
                        <FormItem
                          item={item}
                          checked={checked}
                          handleToggle={handleToggle}
                          handleNodeEditClick={handleNodeEditClick}
                        />
                      </div>
                    );
                  })}
              </List>
            )}

          {state &&
            state.hash &&
            state.node.isEnabled &&
            !indexForms.filter((f: Helper.IndexForm) => f.isCustomForm)
              .length && (
              <FormsEmptyList
                message={t("No custom forms have been created or published")}
              />
            )}
        </CardContent>
      </Card>

      <Card
        className={`flex flex-col w-full flex-grow ${
          !state.node.isEnabled ? "bg-gray-200" : ""
        }`}
        style={{
          minHeight: "50px",
          height: "50px",
          flexBasis: "50px",
          flexGrow: "1",
        }}
      >
        <CardTitle title={t("Node Forms")} />
        <CardContent className="flex flex-col flex-grow overflow-y-scroll p-0">
          {state &&
            state.hash &&
            state.node.isEnabled &&
            indexForms.filter((f: Helper.IndexForm) => !f.isCustomForm).length >
              0 && (
              <List className={`p-0 px-3 pr-0 flex-grow flex-1 my-2}`}>
                {indexForms
                  .filter((f: Helper.IndexForm) => !f.isCustomForm)
                  .map((item, index) => {
                    const labelId = `checkbox-list-label-${item.guid}`;
                    return (
                      <div key={item.guid}>
                        <FormItem
                          item={item}
                          checked={checked}
                          editable
                          handleToggle={handleToggle}
                          handleNodeEditClick={handleNodeEditClick}
                        />
                      </div>
                    );
                  })}
              </List>
            )}

          {state &&
            state.hash &&
            state.node.isEnabled &&
            !indexForms.filter((f: Helper.IndexForm) => !f.isCustomForm)
              .length && (
              <FormsEmptyList
                message={t(
                  "No nodes with index forms have been added to the workflow"
                )}
              />
            )}
        </CardContent>
      </Card>
    </>
  );
};
