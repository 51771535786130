// import { ShowState } from './ShowState'
import {
  NodeDialogActions,
  NodeContext,
  NodeContextType,
  CustomMessageBox,
  NodeStateActions,
  EditableTextField,
  EnableNameDescriptionFields,
  EnabledSwitch,
} from "@dispatcher-stratus/stratus-react";
import { FormControlLabel, Switch } from "@material-ui/core";
import React, { useState } from "react";
import {
  setNodes,
  getAllIndexFormNodes,
  IndexForm,
} from "./NodeFormPanel/helper";
import ReorderViewPanel from "./NodeFormPanel/ReorderViewPanel";
import { useTranslation } from "react-i18next";
import { FormsPanel } from "./NodeFormPanel/FormsPanel";

export const Node = () => {
  const { state, dispatch } = React.useContext<NodeContextType>(NodeContext);
  const [refreshIndexFormNodes, setRefreshIndexFormNodes] = useState({});
  const { t, i18n } = useTranslation();

  const handleNodesSave = async (): Promise<boolean | object> => {
    const allNodes = await getAllIndexFormNodes(state.nodeid, state.wfid);
    const goodCheckedNodes: IndexForm[] = [];
    state.node.config.nodes.checked?.forEach((node: IndexForm) => {
      if (
        allNodes.findIndex((el) => {
          return el.guid === node.guid;
        }) > -1
      ) {
        goodCheckedNodes.push(node);
      }
    });
    await setNodes(state.nodeid, state.wfid, goodCheckedNodes);
    return {
      ...state,
      node: {
        ...state.node,
        status: {
          ...state.node.status,
          isValid: goodCheckedNodes.length > 0,
          errors:
            goodCheckedNodes.length > 0
              ? undefined
              : { "en-us": ["A form(s) selection is required."] },
        },
      },
    };
  };

  const handleEnableChange = () => {
    dispatch({
      type: NodeStateActions.SET_NODE_COMMON_PROPERTY,
      payload: {
        isEnabled: !state.node.isEnabled,
      },
    });
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: NodeStateActions.SET_NODE_COMMON_PROPERTY,
      payload: {
        name: e.target.value,
      },
    });
  };
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: NodeStateActions.SET_NODE_COMMON_PROPERTY,
      payload: {
        description: e.target.value,
      },
    });
  };

  return (
    <>
      <div className="bg-white flex flex-col h-screen w-screen overflow-hidden">
        <div
          className="flex-grow flex flex-col"
          style={{
            maxHeight: "calc(100vh - 55px)",
            height: "calc(100vh - 55px)",
          }}
        >
          <div className="w-full p-4 pt-0">
          <EnabledSwitch  i18n={i18n} />
            <p className="w-full" style={{ fontSize: "14px" }}>
              {t(
                "Use this node to enable indexing at the MFP Panel, including enabling a user to configure a scan destination upon a scan action. Pre-configured forms will appear in the left-hand panel after their associated nodes have been added to a workflow. Select one or more pre-configured forms to insert them into the workflow. Use the grab handles in the right-hand panel to order the forms as they should appear to the user at the MFP Panel."
              )}
            </p>
          </div>
          <div className="flex-grow flex flex-row gap-4 p-4 pt-0">
            <div
              className="flex flex-col gap-4 flex-grow"
              style={{ flexBasis: "50px", flexGrow: 1, width: "45%" }}
            >
              <EnableNameDescriptionFields i18n={i18n} hideEnabled />
              <FormsPanel refresh={refreshIndexFormNodes} />
            </div>
            <div
              className="flex-grow flex flex-col gap-2 w-0"
              style={{ flexBasis: "50px", flexGrow: 2 }}
            >
              <ReorderViewPanel className="flex-grow" />
            </div>
          </div>
        </div>
        <div className="w-full">
          <NodeDialogActions
            i18n={i18n}
            helpPath={`/process/forms/sec.form-selector/`}
            beforeSave={handleNodesSave}
            onIndexFormNodeUpdate={() => {
              setRefreshIndexFormNodes({});
            }}
          />
        </div>
        {/* <div className='w-full col-span-full flex-grow-0 '>
            <ShowState />
          </div> */}
      </div>
      <CustomMessageBox i18n={i18n} />
    </>
  );
};
