import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";
import * as Helper from "./helper";
import { IconButton, SvgIcon, Tooltip } from "@material-ui/core";
import { ReactComponent as EditIcon } from "../../assets/icon-edit-pencil.svg";
import { useTranslation } from "react-i18next";

export type NodeITemProps = {
  item: Helper.IndexForm;
  checked: Helper.IndexForm[];
  editable?: boolean;
  handleToggle: (value: string) => void;
  handleNodeEditClick: (value: string) => void;
};

export const FormItem = ({
  item,
  checked,
  editable = false,
  handleToggle,
  handleNodeEditClick,
}: NodeITemProps) => {
  const [hideTooltip, setHideTooltip] = React.useState(false);
  const labelId = `checkbox-list-label-${item.guid}`;
  const { t } = useTranslation();

  return (
    <Tooltip
      title={hideTooltip ? "" : item.name}
      enterDelay={500}
      PopperProps={{ disablePortal: true }}
      key={item.guid}
    >
      <div>
        <ListItem
          key={item.guid}
          role={undefined}
          dense
          button
          onClick={() => {
            handleToggle(item.guid);
          }}
          className="pt-0 pb-0"
        >
          <ListItemIcon>
            <Checkbox
              color="primary"
              edge="start"
              size="medium"
              checked={
                checked.findIndex(
                  (el: Helper.IndexForm) => el.guid === item.guid
                ) !== -1
              }
              tabIndex={-1}
              disableRipple
              inputProps={{ "aria-labelledby": labelId }}
              className="p-0"
            />
          </ListItemIcon>

          <ListItemText
            id={labelId}
            primary={`${item.name}`}
            primaryTypographyProps={{
              className: "truncate",
              variant: "body2",
              style: { fontSize: "12px" },
              title: undefined,
            }}
          />
          {editable && (
            <ListItemSecondaryAction>
              <Tooltip
                title={t("Edit Configuration")}
                enterDelay={500}
                onOpen={() => setHideTooltip(true)}
                onClose={() => setHideTooltip(false)}
                PopperProps={{
                  disablePortal: true,
                  className: "whitespace-nowrap break-normal",
                }}
                className="whitespace-nowrap break-normal"
              >
                <div>
                  <IconButton
                    style={Helper.getButtonStyle()}
                    onClick={() => handleNodeEditClick(item.guid)}
                    edge="end"
                    aria-label="edit"
                    size="small"
                    className="p-1"
                  >
                    <SvgIcon fontSize="medium" viewBox="-6 -6 24 24">
                      <EditIcon />
                    </SvgIcon>
                  </IconButton>
                </div>
              </Tooltip>
            </ListItemSecondaryAction>
          )}
        </ListItem>
      </div>
    </Tooltip>
  );
};
