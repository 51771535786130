import { CssBaseline } from "@material-ui/core";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider,
} from "@material-ui/core/styles";
import { create } from "jss";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";

import { Node } from "./components/Node";
import theme from "./theme";
import i18n from "./i18n";
import { I18nextProvider, useTranslation } from "react-i18next";
import {
  MessageBoxContextProvider,
  NodeContextProvider,
} from "@dispatcher-stratus/stratus-react";
import { INITIAL_NODE_CONFIG } from "./interfaces";
import DisableContextAndShortcuts from "./components/DisableContextAndShortcuts";

function App() {
  const { t } = useTranslation("translation", { i18n });
  const routing = (
    <I18nextProvider i18n={i18n}>
      <Router>
        <NodeContextProvider
          configDefaults={INITIAL_NODE_CONFIG}
          windowOptions={{
            title: t("Form Selector"),
          }}
        >
          <MessageBoxContextProvider>
            <Switch>
              <Route path="/client/node">
                <Node />
              </Route>
              <Redirect from="/" to="/client/node" />
            </Switch>
          </MessageBoxContextProvider>
        </NodeContextProvider>
      </Router>
    </I18nextProvider>
  );
  return (
    <StylesProvider jss={jss}>
      <DisableContextAndShortcuts />
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {routing}
      </ThemeProvider>
    </StylesProvider>
  );
}
const jss = create({
  ...jssPreset(),
  // Define a custom insertion point that JSS will look for when injecting the styles into the DOM.
  insertionPoint: document.getElementById("jss-insertion-point")!,
});
export default App;
