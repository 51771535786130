import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import * as Helper from "./helper";
import {
  NodeContext,
  NodeContextType,
  NodeStateActions,
} from "@dispatcher-stratus/stratus-react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";

export interface ReorderViewProps {
  className?: string;
}

export const ReorderView = (props: ReorderViewProps): JSX.Element => {
  // const [checked, setChecked] = React.useState<Helper.IndexForm[]>([])
  const { state, dispatch } = React.useContext<NodeContextType>(NodeContext);
  const { t } = useTranslation();
  const checked: Helper.IndexForm[] = state.node.config.nodes.checked;
  const setChecked = (items: Helper.IndexForm[]) => {
    dispatch({
      type: NodeStateActions.UPDATE_NODE_CONFIG,
      payload: {
        nodes: {
          ...state.node.config.nodes,
          checked: items,
        },
      },
    });
  };

  const onDragEnd = (result: any) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = Helper.reorder(
      checked,
      result.source.index,
      result.destination.index
    ) as Helper.IndexForm[];

    setChecked(items);
  };

  return checked.length === 0 ? (
    <div
      className={
        props.className +
        " font-semibold text-center align text-gray-500 flex justify-center items-center"
      }
    >
      <span className="">{t("No forms selected")}</span>
    </div>
  ) : (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <List
            className={`flex flex-col py-0 px-2 pr-0 ${props.className ?? ""}`}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {checked.map((item: Helper.IndexForm, index: number) => {
              const labelId = `checkbox-list-label-${item.guid}`;

              return (
                <Draggable
                  key={item.guid}
                  draggableId={item.guid}
                  index={index}
                >
                  {(provided, snapshot) => (
                    <ListItem
                      key={item.guid}
                      role={undefined}
                      button
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={Helper.getItemStyle(
                        snapshot.isDragging,
                        provided.draggableProps.style
                      )}
                    >
                      <ListItemIcon className="p-0 m-0 min-w-0 -ml-1 mr-3 opacity-30">
                        <DragIndicatorIcon />
                      </ListItemIcon>
                      <Tooltip
                        title={item.name}
                        enterDelay={500}
                        PopperProps={{ disablePortal: true }}
                      >
                        <ListItemText
                          id={labelId}
                          primary={`${item.name}`}
                          primaryTypographyProps={{
                            className: "whitespace-nowrap truncate",
                            variant: "body2",
                            style: { fontSize: "12px" },
                          }}
                        />
                      </Tooltip>
                    </ListItem>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </List>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default ReorderView;
