import React from "react";

interface CardTitleProps {
  title: string;
  color?: string;
  backgroundColor?: string;
}

const defaultCardTitleProps = {
  color: "#FFFFFF",
  backgroundColor: "#231231",
};

const CardTitle = (props: CardTitleProps): JSX.Element => {
  props = { ...defaultCardTitleProps, ...props };
  return (
    <div
      className="pl-4 py-1 text-base text-white"
      style={{
        color: props.color,
        backgroundColor: props.backgroundColor,
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        marginTop: "-1px",
        marginLeft: "-1px",
        fontSize: "12px",
        fontWeight: "500",
      }}
    >
      {props.title}
    </div>
  );
};

export default CardTitle;
