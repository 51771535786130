import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/translation.json";
import ja from "./locales/ja/translation.json";
import es from "./locales/es/translation.json";
import de from "./locales/de/translation.json";
import it from "./locales/it/translation.json";
import fr from "./locales/fr/translation.json";
import zh from "./locales/zh/translation.json";
import detector from "i18next-browser-languagedetector";

const options = {
  order: ["querystring", "cookie", "localStorage", "navigator"],
  lookupQuerystring: "lang",
  lookupCookie: "i18next",
  lookupLocalStorage: "i18nextLang",
  caches: ["cookie", "localStorage"],
  cookieOptions: { path: "/", sameSite: "strict" },
};

i18next
  .use(detector)
  .use(initReactI18next)
  .init({
    detection: options,
    debug: true,
    ns: ["translation"],
    defaultNS: "translation",
    returnEmptyString: false,
    nsSeparator: "::",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
    nonExplicitSupportedLngs: true, //support language variation
    resources: {
      en: {
        translation: en,
      },
      es: {
        translation: es,
      },
      de: {
        translation: de,
      },
      fr: {
        translation: fr,
      },
      it: {
        translation: it,
      },
      ja: {
        translation: ja,
      },
      zh: {
        translation: zh,
      },
    },
  });

export default i18next;
